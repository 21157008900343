var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{class:_vm.isLessThan ? 'border-custom-rowspan-2' : ''},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-layout',[_c('validation-provider',{attrs:{"name":"textRequired","vid":("weight-" + (_vm.item.vId)),"rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isLessThan)?_c('v-text-field',{staticClass:"rounded-lg input-combine-weight mt-3",attrs:{"outlined":"","dense":"","maxlength":_vm.maximumWeight,"placeholder":_vm.$t('number_of_pounds'),"error-messages":errors,"messages":_vm.item.errorWeight,"disabled":_vm.disableItem},on:{"blur":function($event){return _vm.onBlurWeight($event, _vm.idx, _vm.item)},"keypress":function($event){return _vm.isNumber($event)},"input":_vm.onInputWeight,"paste":function($event){$event.preventDefault();return _vm.onPasteWeight($event)}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{staticClass:"error--text",domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(_vm.item.weight),callback:function ($$v) {_vm.$set(_vm.item, "weight", $$v)},expression:"item.weight"}},[(_vm.isShowIconDel && !_vm.disableItem)?_c('v-icon',{attrs:{"slot":"prepend"},on:{"click":function($event){return _vm.onDeleteItem(_vm.idx)}},slot:"prepend"},[_vm._v(" "+_vm._s('mdi-delete')+" ")]):_c('v-icon',{staticClass:"ml-6",attrs:{"slot":"prepend"},slot:"prepend"}),_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v(" "+_vm._s(_vm.item.isMax ? 'mdi-greater-than' : 'mdi-less-than-or-equal')+" ")])],1):_c('v-text-field',{staticClass:"rounded-lg input-combine-weight",attrs:{"outlined":"","dense":"","hide-details":"","maxlength":_vm.maximumWeight,"placeholder":_vm.$t('number_of_pounds'),"error-messages":errors,"disabled":""},model:{value:(_vm.item.weight),callback:function ($$v) {_vm.$set(_vm.item, "weight", $$v)},expression:"item.weight"}},[_c('v-icon',{staticClass:"ml-6",attrs:{"slot":"prepend"},slot:"prepend"}),_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v(" "+_vm._s(_vm.isLessThan ? 'mdi-less-than-or-equal' : 'mdi-greater-than')+" ")])],1)]}}])})],1)],1)],1),_c('td',{staticClass:"border-custom-rowspan-2"},[_c('v-layout',{staticClass:"mx-2 mt-4",attrs:{"column":""}},[_c('v-layout',{staticClass:"my-1",attrs:{"justify-center":""}},[_c('validation-provider',{class:_vm.isLessThan ? 'w-fluid' : '',attrs:{"name":"textRequired","vid":("intracityPrice-" + (_vm.item.vId)),"rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg shrink",attrs:{"outlined":"","dense":"","error-messages":errors,"placeholder":_vm.$t('shipping_cost'),"maxlength":_vm.maximumPrice,"disabled":_vm.disableItem},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.onInputIntracityPrice,"paste":function($event){$event.preventDefault();return _vm.onPasteIntracityPrice($event)}},model:{value:(_vm.item.intracityPrice),callback:function ($$v) {_vm.$set(_vm.item, "intracityPrice", $$v)},expression:"item.intracityPrice"}})]}}])}),(!_vm.isLessThan)?_c('v-layout',{staticClass:"ml-2",attrs:{"justify-center":"","align-center":""}},[_c('validation-provider',{attrs:{"name":"textRequired","vid":("intracityAccumulatePrice-" + (_vm.item.vId)),"rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","prepend-icon":"mdi-plus","placeholder":((_vm.$t('plus_price')) + "/" + (_vm.$t('kg'))),"maxlength":_vm.maximumPrice,"suffix":("/" + (_vm.$t('kg'))),"error-messages":errors,"disabled":_vm.disableItem},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.onInputIntracityAccumulatePrice,"paste":function($event){$event.preventDefault();return _vm.onPasteIntracityAccumulatePrice($event)}},model:{value:(_vm.item.intracityAccumulatePrice),callback:function ($$v) {_vm.$set(_vm.item, "intracityAccumulatePrice", $$v)},expression:"item.intracityAccumulatePrice"}})]}}],null,false,1631030103)})],1):_vm._e()],1)],1)],1),_c('td',{staticClass:"border-custom-rowspan-2"},[_c('v-layout',{staticClass:"mx-2 mt-4",attrs:{"column":""}},[_c('v-layout',{staticClass:"my-1",attrs:{"justify-center":""}},[_c('validation-provider',{class:_vm.isLessThan ? 'w-fluid' : '',attrs:{"name":"textRequired","vid":("intrazonePrice-" + (_vm.item.vId)),"rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","error-messages":errors,"placeholder":_vm.$t('shipping_cost'),"maxlength":_vm.maximumPrice,"disabled":_vm.disableItem},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.onInputIntrazonePrice,"paste":function($event){$event.preventDefault();return _vm.onPasteIntrazonePrice($event)}},model:{value:(_vm.item.intrazonePrice),callback:function ($$v) {_vm.$set(_vm.item, "intrazonePrice", $$v)},expression:"item.intrazonePrice"}})]}}])}),(!_vm.isLessThan)?_c('v-layout',{staticClass:"ml-2",attrs:{"justify-center":"","align-center":""}},[_c('validation-provider',{attrs:{"name":"textRequired","vid":("intrazoneAccumulatePrice-" + (_vm.item.vId)),"rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","prepend-icon":"mdi-plus","error-messages":errors,"placeholder":((_vm.$t('plus_price')) + "/" + (_vm.$t('kg'))),"maxlength":_vm.maximumPrice,"suffix":("/" + (_vm.$t('kg'))),"disabled":_vm.disableItem},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.onInputIntrazoneAccumulatePrice,"paste":function($event){$event.preventDefault();return _vm.onPasteIntrazoneAccumulatePrice($event)}},model:{value:(_vm.item.intrazoneAccumulatePrice),callback:function ($$v) {_vm.$set(_vm.item, "intrazoneAccumulatePrice", $$v)},expression:"item.intrazoneAccumulatePrice"}})]}}],null,false,3258695790)})],1):_vm._e()],1)],1)],1),_c('td',{staticClass:"border-custom-rowspan-2"},[_c('v-layout',{staticClass:"mx-2 mt-4",attrs:{"column":""}},[_c('v-layout',{staticClass:"my-1",attrs:{"justify-center":""}},[_c('validation-provider',{class:_vm.isLessThan ? 'w-fluid' : '',attrs:{"name":"textRequired","vid":("interzonePrice-" + (_vm.item.vId)),"rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","error-messages":errors,"placeholder":_vm.$t('shipping_cost'),"maxlength":_vm.maximumPrice,"disabled":_vm.disableItem},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.onInputInterzonePrice,"paste":function($event){$event.preventDefault();return _vm.onPasteInterzonePrice($event)}},model:{value:(_vm.item.interzonePrice),callback:function ($$v) {_vm.$set(_vm.item, "interzonePrice", $$v)},expression:"item.interzonePrice"}})]}}])}),(!_vm.isLessThan)?_c('v-layout',{staticClass:"ml-2",attrs:{"justify-center":"","align-center":""}},[_c('validation-provider',{class:_vm.isLessThan ? 'w-fluid' : '',attrs:{"name":"textRequired","vid":("interzoneAccumulatePrice-" + (_vm.item.vId)),"rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","prepend-icon":"mdi-plus","error-messages":errors,"placeholder":((_vm.$t('plus_price')) + "/" + (_vm.$t('kg'))),"maxlength":_vm.maximumPrice,"suffix":("/" + (_vm.$t('kg'))),"disabled":_vm.disableItem},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.onInputInterzoneAccumulatePrice,"paste":function($event){$event.preventDefault();return _vm.onPasteInterzoneAccumulatePrice($event)}},model:{value:(_vm.item.interzoneAccumulatePrice),callback:function ($$v) {_vm.$set(_vm.item, "interzoneAccumulatePrice", $$v)},expression:"item.interzoneAccumulatePrice"}})]}}],null,false,1870215946)})],1):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }