import constants from '@/constants'
import { StringUtils } from '@/helpers/stringUtils'

const initItemPrice = {
  vId: 0,
  weight: '',
  errorWeight: '',
  transportType: '',
  intracityPrice: '',
  intracityAccumulatePrice: '',
  intrazonePrice: '',
  intrazoneAccumulatePrice: '',
  interzonePrice: '',
  interzoneAccumulatePrice: '',
  status: 1,
  isMax: false
}
var mixinPriceList = {
  data() {
    return {
      isCreated: false,
      invalidWeight: false,
      autoIncrease: 1,
      lstPriceOrigin: [],
      lstPrice: [],

      lstDeleteOrigin: [],

      // loading
      showLoading: false,
      showConfirmTokenExpried: false,
      // notifications
      showNoti: false,
      typeIcon: constants.typeAlert.warning,
      message: '',

      showUpdate: false,
      textCancel: '',
      textAccept: ''
    }
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeIcon)
    },
    lenLstPrice() {
      return this.lstPrice.length
    }
  },
  methods: {
    async parseToLocalPrices(data) {
      const tmp = []
      const len = data.length
      data.forEach((item, index) => {
        const isMax = index >= len - 1
        const itemLocal = this.parseInitItemLocal(item, isMax)
        tmp.splice(index, 0, itemLocal)
      })
      this.lstPrice = tmp
    },
    parseInitItemLocal(item, isMax) {
      const itemLocal = this.getInitItemLocal()
      itemLocal.vId = item.id
      itemLocal.weight = isMax
        ? StringUtils.formatDecimal(item.fromWeight)
        : StringUtils.formatDecimal(item.toWeight)
      itemLocal.transportType = item.transportType
      itemLocal.intracityPrice = StringUtils.formatDecimal(item.intracityPrice)
      itemLocal.intrazonePrice = StringUtils.formatDecimal(item.intrazonePrice)
      itemLocal.interzonePrice = StringUtils.formatDecimal(item.interzonePrice)
      if (isMax) {
        itemLocal.intracityAccumulatePrice = StringUtils.formatDecimal(
          item.intracityAccumulatePrice
        )
        itemLocal.intrazoneAccumulatePrice = StringUtils.formatDecimal(
          item.intrazoneAccumulatePrice
        )
        itemLocal.interzoneAccumulatePrice = StringUtils.formatDecimal(
          item.interzoneAccumulatePrice
        )
        itemLocal.isMax = isMax
      }

      return itemLocal
    },
    getInitItemLocal() {
      return { ...initItemPrice }
    },
    getHighestPriceId() {
      if (this.lstPriceOrigin.length === 0) return
      const highest = this.lstPriceOrigin.reduce((a, b) =>
        Number(a.id) > Number(b.id) ? a : b
      )

      return highest.id
    },
    async initItem() {
      await this.addItem(true, false)
      await this.addItem(true, true)
    },
    async addItem(isPush, isMax) {
      await this.addItemIndividual(isPush, isMax)
    },
    async addItemIndividual(isPush, isMax) {
      const item = this.getInitItemLocal()
      item.vId = this.autoIncrease
      item.transportType = constants.transportType.individual
      if (isMax) {
        item.isMax = isMax
      }
      if (isPush) {
        this.lstPrice.push({ ...item })
      } else {
        this.lstPrice.splice(this.lenLstPrice - 1, 0, { ...item })
      }
      this.setInvalidWeight(true)
      this.autoIncrease++
    },
    async onBlurWeight(evt, idx, item) {
      const val = evt.target.value
      await this.bindWeightValueToItemShip(idx, val)
      await this.bindMaxWeight()
      this.validateWeightVal(item)
    },
    setInvalidWeight(state) {
      this.invalidWeight = state
      if (!state) {
        this.lstPrice.forEach((item) => {
          item.errorWeight = ''
        })
      }
    },
    async bindWeightValueToItemShip(idx, val) {
      const itemShip = this.lstPrice.at(this.lstPrice.length - 1)
      if (typeof itemShip !== constants.undefined) {
        itemShip.weight = val
      }
    },
    // check duplidacte weight
    validateWeightVal(item) {
      this.setInvalidWeight(false);
      if (this.lstPrice.length >= 2) {
        // check duplicate
        var duplicatePrices = [];
        this.lstPrice.forEach((price) => {
          var findDuplicates = this.lstPrice.filter(
            (x) =>
              x.weight !== '' &&
              StringUtils.numStrToFloat(x.weight) ===
                StringUtils.numStrToFloat(price.weight) &&
              !x.isMax
          )
          if (findDuplicates.length >= 2) {
            duplicatePrices.push(price);
          }
        })
        var emptyWeights = this.lstPrice.filter((x) => x.weight === '');
        if ((duplicatePrices.length > 0) || (emptyWeights.length > 0)) {
          this.setInvalidWeight(true);
          this.lstPrice.forEach((price) => {
            if (duplicatePrices.includes(price)) {
              price.errorWeight = this.$t('weight_range_declared');
            } else {
              if (emptyWeights.includes(price)) {
                price.errorWeight = this.$t('message_required');
              } else {
                price.errorWeight = '';
              }
            }
          })
        }
      }
    },
    checkInvalidWeight() {
      let countValid = 0
      this.lstPrice.forEach((item) => {
        if (item.weight === null || item.weight === '') {
          this.setInvalidWeight(true)
          return
        } else {
          countValid++
        }
      })

      if (countValid === this.lenLstPrice) {
        this.setInvalidWeight(false)
      }
    },
    async onDeleteItem(idx) {
      await this.deleteItemLocal(idx)
      this.validateWeightVal(idx)
      this.checkInvalidWeight()
      this.validateWeightVal()
      this.bindMaxWeight()
    },
    resetErrorWeight() {
      this.lstPrice.forEach((item) => {
        item.errorWeight = ''
      })
    },
    async bindMaxWeight() {
      const max = this.getMaxWeight()
      if (max !== '') {
        this.lstPrice[this.lenLstPrice - 1].weight = max
      }
    },
    getMaxWeight() {
      const lstWeight = []
      this.lstPrice.forEach((item) => {
        if (
          item.weight !== null &&
          item.weight !== '' &&
          item.transportType !== constants.transportType.ship &&
          !item.isMax
        ) {
          const val = StringUtils.numStrToFloat(item.weight)
          lstWeight.push(val)
        }
      })
      if (lstWeight.length > 0) {
        return StringUtils.formatDecimal(Math.max(...lstWeight))
      }

      return ''
    },
    sortPricesByWeight() {
      return this.lstPrice.sort(
        (a, b) =>
          StringUtils.numStrToFloat(a.weight) -
          StringUtils.numStrToFloat(b.weight)
      )
    },
    sortAdminPricesByWeight() {
      var maxPrice = this.lstPrice.find((price) => price.isMax);
      return this.lstPrice.sort(
        (a, b) =>
          (a.weight.trim() !== "" ? StringUtils.numStrToFloat(a.weight) : maxPrice.weight) -
          (b.weight.trim() !== "" ? StringUtils.numStrToFloat(b.weight) : maxPrice.weight)
      )
    },
    findItemOriginById(id) {
      if (this.lstPriceOrigin === null || this.lstPriceOrigin.length === 0) {
        return null
      }

      return this.lstPriceOrigin.find((o) => o.id === id)
    },
    checkItemChange(iOrigin, iNew, isMax) {
      if (iNew.fromWeight !== iOrigin.fromWeight) {
        return true
      }

      if (!isMax && iNew.toWeight !== iOrigin.toWeight) {
        return true
      }

      if (iNew.intracityPrice !== iOrigin.intracityPrice) {
        return true
      }

      if (
        isMax &&
        iNew.intracityAccumulatePrice !== iOrigin.intracityAccumulatePrice
      ) {
        return true
      }

      if (iNew.intrazonePrice !== iOrigin.intrazonePrice) {
        return true
      }

      if (
        isMax &&
        iNew.intrazoneAccumulatePrice !== iOrigin.intrazoneAccumulatePrice
      ) {
        return true
      }

      if (iNew.interzonePrice !== iOrigin.interzonePrice) {
        return true
      }

      if (
        isMax &&
        iNew.interzoneAccumulatePrice !== iOrigin.interzoneAccumulatePrice
      ) {
        return true
      }

      return false
    },
    onClickBtn() {
      if (this.isCreated) {
        this.message = this.$t('to_save_changes')
        this.textCancel = this.$t('cancel')
        this.textAccept = this.$t('save')
        this.showUpdate = true
      } else {
        this.createPrices()
      }
    },
    onCancelUpdate() {
      this.showUpdate = false
    },
    onAcceptUpdate() {
      this.showUpdate = false
      this.updatePrices()
    },
    async deleteItemLocal(idx) {
      const del = this.lstPrice.splice(idx, 1)
      const exist = this.findItemOriginById(del[0].vId)
      if (
        typeof exist !== constants.undefined &&
        this.isCreated &&
        exist !== null &&
        exist.id > 0
      ) {
        this.lstDeleteOrigin.push(exist)
      }
      // TODO
      // const delShip = this.lstPrice.splice(idx, 1)
      // console.log(delShip)
      // const existShip = this.findItemOriginById(delShip[0].vId)
      // if (
      //   typeof existShip !== constants.undefined &&
      //   this.isCreated &&
      //   exist !== null &&
      //   existShip.id > 0
      // ) {
      //   this.lstDeleteOrigin.push(existShip)
      // }
    },
    toggleDialogNoti(
      pars = {
        state: false,
        type: constants.typeAlert.warning,
        msg: ''
      }
    ) {
      this.showNoti = pars.state
      this.typeNoti = pars.type
      this.message = pars.msg
    }
  }
}

export default mixinPriceList
