<template>
  <tr>
    <td
      :class="isLessThan ? 'border-custom-rowspan-2' : ''"
    >
      <v-layout justify-center align-center>
        <v-layout>
          <validation-provider
            v-slot="{ errors }"
            name="textRequired"
            :vid="`weight-${item.vId}`"
            :rules="requiredRules"
          >
            <v-text-field
              v-if="isLessThan"
              v-model="item.weight"
              outlined
              dense
              class="rounded-lg input-combine-weight mt-3"
              :maxlength="maximumWeight"
              :placeholder="$t('number_of_pounds')"
              :error-messages="errors"
              :messages="item.errorWeight"
              :disabled="disableItem"
              @blur="onBlurWeight($event, idx, item)"
              @keypress="isNumber($event)"
              @input="onInputWeight"
              @paste.prevent="onPasteWeight($event)"
            >
              <v-icon
                v-if="isShowIconDel && !disableItem"
                slot="prepend"
                @click="onDeleteItem(idx)"
              >
                {{ 'mdi-delete' }}
              </v-icon>
              <v-icon v-else slot="prepend" class="ml-6" />

              <v-icon slot="prepend-inner">
                {{ item.isMax ? 'mdi-greater-than' : 'mdi-less-than-or-equal' }}
              </v-icon>
              <template v-slot:message="{ message }">
                <span class="error--text" v-html="message" />
              </template>
            </v-text-field>

            <v-text-field
              v-else
              v-model="item.weight"
              outlined
              dense
              hide-details
              class="rounded-lg input-combine-weight"
              :maxlength="maximumWeight"
              :placeholder="$t('number_of_pounds')"
              :error-messages="errors"
              disabled
            >
              <v-icon slot="prepend" class="ml-6" />
              <v-icon slot="prepend-inner">
                {{ isLessThan ? 'mdi-less-than-or-equal' : 'mdi-greater-than' }}
              </v-icon>
            </v-text-field>
          </validation-provider>
        </v-layout>
      </v-layout>
    </td>
    <td class="border-custom-rowspan-2">
      <v-layout column class="mx-2 mt-4">
        <v-layout justify-center class="my-1">
          <validation-provider
            v-slot="{ errors }"
            name="textRequired"
            :vid="`intracityPrice-${item.vId}`"
            :rules="requiredRules"
            :class="isLessThan ? 'w-fluid' : ''"
          >
            <v-text-field
              v-model="item.intracityPrice"
              outlined
              dense
              class="rounded-lg shrink"
              :error-messages="errors"
              :placeholder="$t('shipping_cost')"
              :maxlength="maximumPrice"
              :disabled="disableItem"
              @keypress="isNumber($event)"
              @input="onInputIntracityPrice"
              @paste.prevent="onPasteIntracityPrice($event)"
            />
          </validation-provider>

          <v-layout v-if="!isLessThan" justify-center align-center class="ml-2">
            <validation-provider
              v-slot="{ errors }"
              name="textRequired"
              :vid="`intracityAccumulatePrice-${item.vId}`"
              :rules="requiredRules"
            >
              <v-text-field
                v-model="item.intracityAccumulatePrice"
                outlined
                dense
                class="rounded-lg"
                prepend-icon="mdi-plus"
                :placeholder="`${$t('plus_price')}/${$t('kg')}`"
                :maxlength="maximumPrice"
                :suffix="`/${$t('kg')}`"
                :error-messages="errors"
                :disabled="disableItem"
                @keypress="isNumber($event)"
                @input="onInputIntracityAccumulatePrice"
                @paste.prevent="onPasteIntracityAccumulatePrice($event)"
              />
            </validation-provider>
          </v-layout>
        </v-layout>
      </v-layout>
    </td>
    <td class="border-custom-rowspan-2">
      <v-layout column class="mx-2 mt-4">
        <v-layout justify-center class="my-1">
          <validation-provider
            v-slot="{ errors }"
            name="textRequired"
            :vid="`intrazonePrice-${item.vId}`"
            :rules="requiredRules"
            :class="isLessThan ? 'w-fluid' : ''"
          >
            <v-text-field
              v-model="item.intrazonePrice"
              outlined
              dense
              class="rounded-lg"
              :error-messages="errors"
              :placeholder="$t('shipping_cost')"
              :maxlength="maximumPrice"
              :disabled="disableItem"
              @keypress="isNumber($event)"
              @input="onInputIntrazonePrice"
              @paste.prevent="onPasteIntrazonePrice($event)"
            />
          </validation-provider>
          <v-layout v-if="!isLessThan" justify-center align-center class="ml-2">
            <validation-provider
              v-slot="{ errors }"
              name="textRequired"
              :vid="`intrazoneAccumulatePrice-${item.vId}`"
              :rules="requiredRules"
            >
              <v-text-field
                v-model="item.intrazoneAccumulatePrice"
                outlined
                dense
                class="rounded-lg"
                prepend-icon="mdi-plus"
                :error-messages="errors"
                :placeholder="`${$t('plus_price')}/${$t('kg')}`"
                :maxlength="maximumPrice"
                :suffix="`/${$t('kg')}`"
                :disabled="disableItem"
                @keypress="isNumber($event)"
                @input="onInputIntrazoneAccumulatePrice"
                @paste.prevent="onPasteIntrazoneAccumulatePrice($event)"
              />
            </validation-provider>
          </v-layout>
        </v-layout>
      </v-layout>
    </td>
    <td class="border-custom-rowspan-2">
      <v-layout column class="mx-2 mt-4">
        <v-layout justify-center class="my-1">
          <validation-provider
            v-slot="{ errors }"
            name="textRequired"
            :vid="`interzonePrice-${item.vId}`"
            :rules="requiredRules"
            :class="isLessThan ? 'w-fluid' : ''"
          >
            <v-text-field
              v-model="item.interzonePrice"
              outlined
              dense
              class="rounded-lg"
              :error-messages="errors"
              :placeholder="$t('shipping_cost')"
              :maxlength="maximumPrice"
              :disabled="disableItem"
              @keypress="isNumber($event)"
              @input="onInputInterzonePrice"
              @paste.prevent="onPasteInterzonePrice($event)"
            />
          </validation-provider>
          <v-layout v-if="!isLessThan" justify-center align-center class="ml-2">
            <validation-provider
              v-slot="{ errors }"
              name="textRequired"
              :vid="`interzoneAccumulatePrice-${item.vId}`"
              :rules="requiredRules"
              :class="isLessThan ? 'w-fluid' : ''"
            >
              <v-text-field
                v-model="item.interzoneAccumulatePrice"
                outlined
                dense
                class="rounded-lg"
                prepend-icon="mdi-plus"
                :error-messages="errors"
                :placeholder="`${$t('plus_price')}/${$t('kg')}`"
                :maxlength="maximumPrice"
                :suffix="`/${$t('kg')}`"
                :disabled="disableItem"
                @keypress="isNumber($event)"
                @input="onInputInterzoneAccumulatePrice"
                @paste.prevent="onPasteInterzoneAccumulatePrice($event)"
              />
            </validation-provider>
          </v-layout>
        </v-layout>
      </v-layout>
    </td>
  </tr>
</template>

<script>
import constants from '@/constants'
import { FormUtils } from '@/helpers/formUtils'
import { StringUtils } from '@/helpers/stringUtils'

export default {
  name: 'InputItemPrice',
  props: {
    item: {
      type: Object,
      default: null
    },
    idx: {
      type: Number,
      default: null
    },
    onBlurWeight: {
      type: Function,
      default: null
    },
    onDeleteItem: {
      type: Function,
      default: null
    },
    disableItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      maximumWeight: constants.maximumWeight,
      maximumPrice: constants.maximumPriceFm,
      requiredRules: {
        required: true
      }
    }
  },
  computed: {
    isIndividual() {
      return this.item.transportType === constants.transportType.individual || this.item.transportType === constants.transportType.ship || !this.item.transportType
    },
    isLessThan() {
      return !this.item.isMax
    },
    isShowIconDel() {
      return this.idx > 0 && !this.item.isMax
    }
  },
  methods: {
    isNumber(evt) {
      return FormUtils.isNumberAndOnlyOneDot(evt)
    },
    onInputWeight(val) {
      this.item.errorWeight = ''
      this.item.weight = StringUtils.formatDecimal(val)
    },
    onInputIntracityPrice(val) {
      this.item.intracityPrice = StringUtils.formatDecimal(val)
    },
    onInputIntracityAccumulatePrice(val) {
      this.item.intracityAccumulatePrice = StringUtils.formatDecimal(val)
    },
    onInputIntrazonePrice(val) {
      this.item.intrazonePrice = StringUtils.formatDecimal(val)
    },
    onInputIntrazoneAccumulatePrice(val) {
      this.item.intrazoneAccumulatePrice = StringUtils.formatDecimal(val)
    },
    onInputInterzonePrice(val) {
      this.item.interzonePrice = StringUtils.formatDecimal(val)
    },
    onInputInterzoneAccumulatePrice(val) {
      this.item.interzoneAccumulatePrice = StringUtils.formatDecimal(val)
    },
    onPasteWeight(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPrice
      )
      if (val !== null) {
        this.item.weight = StringUtils.formatDecimal(val)
      }
    },
    onPasteIntracityPrice(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPrice
      )
      if (val !== null) {
        this.item.intracityPrice = StringUtils.formatDecimal(val)
      }
    },
    onPasteIntracityAccumulatePrice(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPrice
      )
      if (val !== null) {
        this.item.intracityAccumulatePrice = StringUtils.formatDecimal(val)
      }
    },
    onPasteIntrazonePrice(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPrice
      )
      if (val !== null) {
        this.item.intrazonePrice = StringUtils.formatDecimal(val)
      }
    },
    onPasteIntrazoneAccumulatePrice(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPrice
      )
      if (val !== null) {
        this.item.intrazoneAccumulatePrice = StringUtils.formatDecimal(val)
      }
    },
    onPasteInterzonePrice(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPrice
      )
      if (val !== null) {
        this.item.interzonePrice = StringUtils.formatDecimal(val)
      }
    },
    onPasteInterzoneAccumulatePrice(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPrice
      )
      if (val !== null) {
        this.item.interzoneAccumulatePrice = StringUtils.formatDecimal(val)
      }
    }
  }
}
</script>
